import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Welcome from "./components/Welcome";
import Newevent from "./components/Newevent";
import AllEvents from "./components/AllEvents";
import Display from "./components/Display";
import EventDetails from "./components/EventDetails";
import AccountSettings from "./components/Account";
import PrivateRoute from "./components/PrivateRoute";
import EventSummary from "./components/EventSummary";
// import List from './components/List';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/login' element={<Login />} />
          <Route path='/welcome' element={<Welcome />} />
          <Route
            path='/new'
            element={
              <PrivateRoute>
                <Newevent />
              </PrivateRoute>
            }
          />
          <Route
            path='/events'
            element={
              <PrivateRoute>
                <AllEvents />
              </PrivateRoute>
            }
          />
          <Route
            path='/display/:eventId'
            element={
              <PrivateRoute>
                <Display />
              </PrivateRoute>
            }
          />
          <Route
            path='/events/:eventId'
            element={
              <PrivateRoute>
                <EventDetails />
              </PrivateRoute>
            }
          />
          <Route
            path='/summary/:eventId'
            element={
              <PrivateRoute>
                <EventSummary />
              </PrivateRoute>
            }
          />
          <Route
            path='/account'
            element={
              <PrivateRoute>
                <AccountSettings />
              </PrivateRoute>
            }
          />
          {/* <Route path ="/list" element={<List/>}/>    */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
