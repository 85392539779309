import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import Logo from "./Logo";

export default function Homepage() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoggedUser = async () => {
      const storedUserId = localStorage.getItem("adminuserId");
      if (storedUserId) {
        navigate("/events");
      }
    };

    checkLoggedUser();
  }, [navigate]); 

  return (
    <div className='homepage-container'>
      <Logo />
      <div className='button-container'>
      <Link to='/login'>
          <button>LOG IN</button>
        </Link>
        <Link to='/signup'>
          <button>SIGN UP</button>
        </Link>
      </div>
    </div>
  );
}
