import React, { useState, useEffect } from "react";
import { getRequest, deleteRequest } from "../apiAdminUser";
import { Link } from "react-router-dom";
import "./style.css";
import Menu from "./Menu";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate, formatTime } from "../utils/dateTimeFormatter";

export default function AllEvents() {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("current");

  const getEvents = async () => {
    try {
      setLoading(true);
      const response = await getRequest("getEvents");
      const data = response.data;

      if (Array.isArray(data.events)) {
        setEvents(data.events);
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Failed to get events:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const filterEvents = (events) => {
    const now = new Date();
    const todayStart = new Date(now.setHours(0, 0, 0, 0));
    const todayEnd = new Date(now.setHours(23, 59, 59, 999));

    switch (filter) {
      case "past":
        return events.filter(
          (event) => new Date(event.event_date) < todayStart
        );
      case "future":
        return events.filter((event) => new Date(event.event_date) > todayEnd);
      case "all":
        return events;
      case "current":
      default:
        return events.filter(
          (event) =>
            new Date(event.event_date) >= todayStart &&
            new Date(event.event_date) <= todayEnd
        );
    }
  };

  const deleteEvent = async (event) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete the event "${
        event.event_name
      }" scheduled for ${formatTime(event.event_time)} on ${formatDate(
        event.event_date
      )} ? This would also delete the attendance data!`
    );
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await deleteRequest("deleteEvent", {
        event_id: event.event_id,
      });

      if (response.status === 200) {
        toast.success("Event deleted successfully");
        getEvents();
      } else {
        throw new Error("Failed to delete the event");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error("Error deleting event. Please try again.");
    }
  };

  const filteredEvents = filterEvents(events);

  const attendeesEvent = (eventId) => {
    navigate(`/events/${eventId}`);
  };

  const eventsMenuItems = [
    { label: "Home", path: "/" },
    { label: "Create Event", path: "/new" },
    { label: "Account Settings", path: "/account" },
    { label: "Log out", path: "logout" },
  ];

  return (
    <>
      <div className='event-container'>
        <ToastContainer />
        <Menu menuItems={eventsMenuItems} />
        <div className='filter-buttons'>
          <button onClick={() => setFilter("past")}>Past Events</button>
          <button onClick={() => setFilter("current")}>Events For Today</button>
          <button onClick={() => setFilter("future")}>Future Events</button>
          <button onClick={() => setFilter("all")}>All Events</button>
        </div>
        <div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className='event-list'>
              {error ? (
                <p className='error-message'>Failed to load events</p>
              ) : events.length === 0 ? (
                <p>No event information to display</p>
              ) : (
                <div>
                  {filter === "past" && <h2>PAST EVENTS</h2>}
                  {filter === "current" && <h2>EVENTS FOR TODAY</h2>}
                  {filter === "future" && <h2>FUTURE EVENTS</h2>}
                  {filter === "all" && <h2>ALL EVENTS</h2>}
                  {filteredEvents.length === 0 ? (
                    <p style={{ marginTop: 25, fontWeight: "bold" }}>
                      No events to display
                    </p>
                  ) : (
                    <div className='card-container'>
                      {filteredEvents.map((event) => (
                        <div className='card' key={event.event_id}>
                          <div className='card-body'>
                            <h5 className='card-title'>{event.event_name}</h5>
                            <p className='card-text'>
                              Date: {formatDate(event.event_date)}
                            </p>
                            <p className='card-text'>
                              Time: {formatTime(event.event_time)}
                            </p>
                            <button
                              className='delete-event-button'
                              onClick={() => deleteEvent(event)}
                            >
                              DELETE
                            </button>
                            <button
                              className='see-attendees-button'
                              onClick={() => attendeesEvent(event.event_id)}
                            >
                              SEE ATTENDEES
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <Link to='/new'>
            <button className='new-event-button'>Add a new event</button>
          </Link>
        </div>
      </div>
    </>
  );
}
