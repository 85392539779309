import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../apiAdminUser";
import "./style.css";
import Menu from "./Menu";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate, formatTime } from "../utils/dateTimeFormatter";

export default function NewEvent() {
  const navigate = useNavigate();

  const [event, setEvent] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const handleEvent = (e) => setEvent(e.target.value);
  const handleDate = (e) => setDate(e.target.value);
  const handleTime = (e) => setTime(e.target.value);

  const handleExit = () => {
    navigate("/events");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (event === "" || date === "" || time === "") {
      toast.error("Please enter all the fields");
      return;
    }

    const isConfirmed = window.confirm(
      `Are you sure you want to schedule ${event} for ${formatDate(
        date
      )} at ${formatTime(time)}?`
    );
    if (!isConfirmed) {
      toast.info("Event creation cancelled");
      return;
    }

    try {
      const data = await postRequest("createEvent", {
        event_name: event,
        event_date: date,
        event_time: time,
      });

      if (data.status === 201) {
        toast.success("New event created successfully");

        setEvent("");
        setDate("");
        setTime("");

        setTimeout(() => {
          navigate("/events");
        }, 2000);
      } else {
        toast.error("Failed to create event. Please try again.");
      }
    } catch (err) {
      console.error("Failed to create event:", err);
      toast.error("Failed to create event. Please try again.");
    }
  };

  const eventsMenuItems = [
    { label: "Home", path: "/" },
    { label: "See Events", path: "/events" },
    { label: "Account Settings", path: "/account" },
    { label: "Log out", path: "logout" },
  ];

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className='login-container'>
      <ToastContainer />
      <Menu menuItems={eventsMenuItems} />
      <div className='form-container'>
        <h3>Set Up New Event</h3>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>Name of Event</label>
            <input
              onChange={handleEvent}
              className='input'
              value={event}
              type='text'
              placeholder='Enter event name'
            />
            <label>Date</label>
            <input
              onChange={handleDate}
              className='input'
              value={date}
              type='date'
              min={today}
            />
            <label>Time</label>
            <input
              onChange={handleTime}
              className='input'
              value={time}
              type='time'
            />
          </div>
          <div className='button-container'>
            <button onClick={handleExit}>⬅︎ Return To All Events</button>
            <button className='button' type='submit'>
              ADD
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
