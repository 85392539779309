import React, { useState, useEffect } from "react";
import { getRequest } from "../apiAdminUser";
import { useParams, useNavigate } from "react-router-dom";
import "./style.css";
import Menu from "./Menu";
import { formatDate, formatTime } from "../utils/dateTimeFormatter";
import jsPDF from "jspdf";
import "jspdf-autotable";

const EventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [attendees, setAttendees] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const eventsMenuItems = [
    { label: "Home", path: "/" },
    { label: "Account Settings", path: "/account" },
    { label: "Log out", path: "logout" },
  ];

  useEffect(() => {
    const fetchAttendees = async () => {
      try {
        const response = await getRequest(`getClockInRecords/${eventId}`);

        setAttendees(response.data.records);
        setEventDetails(response.data.event);
      } catch (err) {
        console.error("Error fetching attendees:", err);
        setError("An error occurred while fetching attendees.");
      } finally {
        setLoading(false);
      }
    };

    fetchAttendees();
  }, [eventId]);

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Event Summary", 14, 22);

    doc.setFontSize(14);
    doc.text(`Event Name: ${eventDetails.event_name}`, 14, 40);
    doc.text(`Event Date: ${formatDate(eventDetails.event_date)}`, 14, 50);
    doc.text(`Event Time: ${formatTime(eventDetails.event_time)}`, 14, 60);

    doc.text("", 14, 70);

    doc.autoTable({
      startY: 70,
      head: [["User ID", "Name", "Email", "Phone", "Clock-in Date & Time"]],
      body: attendees.map((attendee) => [
        attendee.user_id,
        `${attendee.firstname} ${attendee.lastname}`,
        attendee.email,
        attendee.phonenumber,
        new Date(attendee.clockin_timestamp).toLocaleString(),
      ]),
    });

    doc.save(`${eventDetails.event_name}.pdf`);
  };

  const handleDisplayClick = (action) => {
    if (action === "exit") {
      navigate(`/events`);
    } else if (action === "display") {
      window.open(`/summary/${eventId}`, "_blank");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='event-container'>
      <Menu menuItems={eventsMenuItems} />
      <div className='event-summary'>
        <h1>Event Attendees</h1>
        <ul className='event-details'>
          <li>
            <strong>EVENT NAME: </strong>
            {eventDetails.event_name}
          </li>
          <li>
            <strong>EVENT DATE: </strong>
            {formatDate(eventDetails.event_date)}
          </li>
          <li>
            <strong>EVENT TIME: </strong>
            {formatTime(eventDetails.event_time)}
          </li>
        </ul>
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Clock-in Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {attendees.length > 0 ? (
              attendees.map((attendee) => (
                <tr key={attendee.user_id}>
                  <td>{attendee.user_id}</td>
                  <td>{`${attendee.firstname} ${attendee.lastname}`}</td>
                  <td>{attendee.email}</td>
                  <td>{attendee.phonenumber}</td>
                  <td>
                    {new Date(attendee.clockin_timestamp).toLocaleString()}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                No attendees found for this event
              </tr>
            )}
          </tbody>
        </table>
        <div className='button-container'>
          <button onClick={() => handleDisplayClick("exit")}>
            ⬅︎ Return To All Events
          </button>
          {attendees.length > 0 && (
            <button onClick={() => handleDisplayClick("display")}>
              Display Summary
            </button>
          )}
          <button onClick={generatePDF}>🖨️ Download Summary as PDF</button>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
