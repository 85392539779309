import React, { useState, useEffect } from "react";
import { getRequest, patchRequest } from "../apiAdminUser.js";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";

export default function AccountSettings() {
  const [adminId, setAdminId] = useState(null);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [dateofbirth, setDateOfBirth] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [firstlineaddress, setFirstLineAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUserId = localStorage.getItem("adminuserId");
      if (storedUserId) {
        setAdminId(storedUserId);
        try {
          const response = await getRequest(`getAdminById/${storedUserId}`);
          if (response.error) {
            setError(response.error);
          } else {
            const data = response.data;

            // Extract and format date correctly
            const formattedDate = data.adminuser_dateofbirth.split("T")[0];

            setFirstName(data.adminuser_firstname);
            setLastName(data.adminuser_lastname);
            setDateOfBirth(formattedDate);
            setEmail(data.adminuser_emailaddress);
            setPassword(data.adminuser_password);
            setPhoneNumber(data.adminuser_phonenumber);
            setFirstLineAddress(data.adminuser_firstlineaddress);
            setPostcode(data.adminuser_postcode);
          }
        } catch (error) {
          setError("Failed to load user data.");
          console.error("Fetch User Data Error:", error);
        }
      } else {
        setError("User not logged in.");
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      firstname === "" ||
      lastname === "" ||
      dateofbirth === "" ||
      email === "" ||
      password === "" ||
      phonenumber === "" ||
      firstlineaddress === "" ||
      postcode === ""
    ) {
      setError("Please fill in all fields.");
    } else {
      try {
        const response = await patchRequest(`updateUser/${adminId}`, {
          user_id: adminId,
          user_firstname: firstname,
          user_lastname: lastname,
          user_dateofbirth: dateofbirth,
          user_emailaddress: email,
          user_password: password,
          user_phonenumber: phonenumber,
          user_firstlineaddress: firstlineaddress,
          user_postcode: postcode,
        });

        if (response.status === 200) {
          setSubmitted(true);
          navigate("/login");
        } else {
          setError("Failed to update account. Please try again.");
        }
      } catch (error) {
        setError("An error occurred. Please try again later.");
        console.error(error);
      }
    }
  };

  const accountMenuItems = [
    { label: "Home", path: "/" },
    { label: "Log out", path: "logout" },
  ];

  return (
    <>
      <div className='account-settings-container'>
        <Menu menuItems={accountMenuItems} />
        <div style={{marginTop: "25%"}}>
          <h2>Account Settings</h2>

          {error && <p className='error'>{error}</p>}
          {submitted && (
            <p className='success'>Account updated successfully!</p>
          )}

          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label>First Name</label>
              <input
                type='text'
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label>Last Name</label>
              <input
                type='text'
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label>Date of Birth</label>
              <input
                type='date'
                value={dateofbirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label>Email Address</label>
              <input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label>Password</label>
              <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label>Phone Number</label>
              <input
                type='text'
                value={phonenumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label>First Line of Address</label>
              <input
                type='text'
                value={firstlineaddress}
                onChange={(e) => setFirstLineAddress(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label>Postcode</label>
              <input
                type='text'
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
            </div>

            <button type='submit'>Update Account</button>
          </form>
        </div>
      </div>
    </>
  );
}
