import React, { useEffect, useState } from "react";
import { postRequest } from "../apiAdminUser";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import Menu from "./Menu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignUp() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    dateofbirth: "",
    email: "",
    password: "",
    phonenumber: "",
    firstlineaddress: "",
    postcode: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const requiredFields = [
    "firstname",
    "lastname",
    "dateofbirth",
    "email",
    "password",
    "phonenumber",
    "firstlineaddress",
    "postcode",
  ];

  const handleValidation = () => {
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.replace(/_/g, " ")} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      toast.error("Please enter all required fields");
      return;
    }

    try {
      setIsLoading(true);
      const data = await postRequest("createAdminUser", {
        adminuser_firstname: formData.firstname,
        adminuser_lastname: formData.lastname,
        adminuser_dateofbirth: formData.dateofbirth,
        adminuser_emailaddress: formData.email,
        adminuser_password: formData.password,
        adminuser_phonenumber: formData.phonenumber,
        adminuser_firstlineaddress: formData.firstlineaddress,
        adminuser_postcode: formData.postcode,
      });

      if (data.status === 201) {
        toast.success("Successfully registered!");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        if (data.response.status === 409) {
          return toast.error(data.response.data.message);
        }
        toast.error("An error occurred during registration");
      }
    } catch (err) {
      console.error("Error during registration:", err);
      toast.error("An error occurred during registration");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const signInMenuItems = [
    { label: "Home", path: "/" },
    { label: "Log In", path: "/login" },
  ];

  useEffect(() => {
    const checkLoggedUser = async () => {
      const storedUserId = localStorage.getItem("adminuserId");
      if (storedUserId) {
        navigate("/events");
      }
    };

    checkLoggedUser();
  }, [navigate]);

  return (
    <div className='signup-container'>
      <ToastContainer />
      <Menu menuItems={signInMenuItems} />
      <div className='form-container'>
        <h3>Sign up</h3>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>First Name</label>
            <input
              name='firstname'
              onChange={handleChange}
              className='input'
              value={formData.firstname}
              type='text'
            />
            {errors.firstname && (
              <p className='error-message'>{errors.firstname}</p>
            )}

            <label>Surname</label>
            <input
              name='lastname'
              onChange={handleChange}
              className='input'
              value={formData.lastname}
              type='text'
            />
            {errors.lastname && (
              <p className='error-message'>{errors.lastname}</p>
            )}

            <label>Date of Birth</label>
            <input
              name='dateofbirth'
              onChange={handleChange}
              className='input'
              value={formData.dateofbirth}
              type='date'
            />
            {errors.dateofbirth && (
              <p className='error-message'>{errors.dateofbirth}</p>
            )}

            <label>Email Address</label>
            <input
              name='email'
              onChange={handleChange}
              className='input'
              value={formData.email}
              type='email'
            />
            {errors.email && <p className='error-message'>{errors.email}</p>}

            <label>Password</label>
            <input
              name='password'
              onChange={handleChange}
              className='input'
              value={formData.password}
              type='password'
            />
            {errors.password && (
              <p className='error-message'>{errors.password}</p>
            )}

            <label>Phone Number</label>
            <input
              name='phonenumber'
              onChange={handleChange}
              className='input'
              value={formData.phonenumber}
              type='text'
            />
            {errors.phonenumber && (
              <p className='error-message'>{errors.phonenumber}</p>
            )}

            <label>First Line of Address</label>
            <input
              name='firstlineaddress'
              onChange={handleChange}
              className='input'
              value={formData.firstlineaddress}
              type='text'
            />
            {errors.firstlineaddress && (
              <p className='error-message'>{errors.firstlineaddress}</p>
            )}

            <label>Postcode</label>
            <input
              name='postcode'
              onChange={handleChange}
              className='input'
              value={formData.postcode}
              type='text'
            />
            {errors.postcode && (
              <p className='error-message'>{errors.postcode}</p>
            )}
          </div>
          <div className='button-container'>
            <button disabled={isLoading} className='button' type='submit'>
              {isLoading ? "Signing Up..." : "SIGN UP"}
            </button>
          </div>
        </form>
        Already have an account? <Link to='/login'>Sign In</Link>
      </div>
    </div>
  );
}
