import axios from "axios";
// const apiBaseUrl = "http://192.168.1.104:3000/api";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL;

const getRequest = async (route) => {
  const result = await axios({
    method: "get",
    url: `${apiBaseUrl}/${route}`,
  });
  return result; //returns result, this function (number 2) is complete and now goes to const
  //data line to run the rest of the 1st function which is in apiClient.js
};

const postRequest = async (route, body) => {
  try {
    const result = await axios.post(`${apiBaseUrl}/${route}`, body)
    
    return result  //returns result, this function (number 2) is complete and now goes to const
    //data line to run the rest of the 1st function which is in apiClient.js
  } catch (error) {
    console.error(error);
    return error
  }
  //data line to run the rest of the 1st function
};


const deleteRequest = async (route, body) => {
  try {
    const result = await axios.delete(`${apiBaseUrl}/${route}`, {
      data: body, // Send body as data
    });
    return result;
  } catch (error) {
    console.error("Error in deleteRequest:", error.response || error.message);
    return "An error occurred";
  }
};

const patchRequest = async (route, body) => {
  try {
    const result = await axios.patch(`${apiBaseUrl}/${route}`, body);
    return result;
  } catch (error) {
    console.error("PATCH Request Error:", error);

    // Optionally, you can return an error object or re-throw the error to handle it in the caller function
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
      return error.response;
    } else if (error.request) {
      // Request was made but no response received
      console.error("Request data:", error.request);
    } else {
      // Something happened in setting up the request
      console.error("Error message:", error.message);
    }
    
    throw error; // Re-throw the error for further handling
  }
};


export { getRequest, postRequest, deleteRequest, patchRequest };
