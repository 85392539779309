module.exports = {
  formatDate: (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  },

  formatTime: (timeString) => {
    const time = new Date(`1970-01-01T${timeString}Z`);

    let hours = time.getUTCHours();
    const minutes = String(time.getUTCMinutes()).padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${String(hours).padStart(2, "0")}:${minutes} ${period}`;
  },
};
