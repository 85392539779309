import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import List from "./List";
import "./style.css";

export default function Display() {
  const { eventId } = useParams(); // Get the event ID from URL params
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(""); // State to store the selected date

  const handleSearch = () => {
    // Implement search functionality here
    alert(`Searching for: ${searchQuery}`);
  };

  const handleDateChange = (e) => {
    // Update the selected date
    setSelectedDate(e.target.value);
  };

  return (
    <div className='display-container'>
      <div className='search-container'>
        <input
          type='text'
          className='search-bar'
          placeholder='Filter by date'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>
          <i className='fa fa-search search-icon'></i>
        </button>
      </div>
      <div className='date-selector'>
        <input
          type='date'
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>
      <div className='date-text'>
        {selectedDate || "16/05/2024"} {/* Display selected date or default */}
      </div>
      <div className='attendees'>
        Today,
        <br />
        we have <br />
        33 <br />
        attendees!
      </div>
      <div className='button-container2'>
        <button className='small-button'>
          <Link to={`/events/${eventId}`}>SEE LIST</Link>
        </button>
      </div>
      {/* Include AttendeesList component */}
      <List eventId={eventId} date={selectedDate} />
    </div>
  );
}
